<template>
  <div>
    <b-row>
      <b-col class="text-right" v-show="!filter_show">
        <b-button squared v-on:click="showFilter">Filter</b-button>
        <b-button variant="success" squared v-on:click="updatePage(current_page)"><b-icon-arrow-repeat></b-icon-arrow-repeat></b-button>
      </b-col>
      <b-col v-show="filter_show">
        <b-form  @submit.stop.prevent>

          <b-form-select v-model="filter_author_select" :options="filter_author_options">
            <template #first>
              <b-form-select-option :value="null">-- Select User --</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-input v-model="filter_text" placeholder="Contains text..."></b-form-input>
          <b-row>
            <b-col cols="12" lg="6">
              <b-input-group>
                <b-form-datepicker v-model="filter_start_date" reset-button placeholder="Start Date"></b-form-datepicker>
                <b-form-datepicker v-model="filter_end_date" reset-button placeholder="End Date"></b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col cols="6">
              <b-form-select v-model="filter_tag" class="mb-3">
                <b-form-select-option :value="null">-- Select Tag --</b-form-select-option>
                <b-form-select-option v-for="tag in tags" :key="tag.id" :value="tag.id">
                <span class="current-tag"
                      :style="'background-color:'+tag.color"
                >
                  {{tag.id}}
                </span>
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-form-group label="Sort results by:">
            <b-form-radio-group v-model="filter_sort_select" :options="filter_sort_options">
            </b-form-radio-group>
          </b-form-group>
          <div class="text-right">
            <b-button squared variant="primary" v-on:click="applyFilter">Apply</b-button>
            <b-button squared variant="danger" v-on:click="hideFilter">Close Filter</b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
  <b-row v-show="loggedIn">
      <b-col cols="12">
        <SubmissionList :submission_list="submissions"
                        link_prefix="/submission/"
                        :indent="true"
        ></SubmissionList>
        <b-pagination
          v-model="current_page"
          :total-rows="n_logs"
          :per-page="per_page"
          align="center"
          v-on:change="updatePage"
          first-number last-number
        >
        </b-pagination>
      </b-col>
  </b-row>
  </div>
</template>

<script>

import http from "../http-common"
import SubmissionList from "./SubmissionList";

export default {
  name: "Home",
  props: [
    "loggedIn",
    "tags",
    "users",
  ],
  components: {
    SubmissionList,
  },
  data: function () {
    return {
      current_page: 1,
      n_logs: 0,
      per_page: 20,
      submissions: [],
      filter_show: false,
      filter_author_select: null,
      // filter_author_options: [],
      filter_sort_options: ["relevance", "most recent"],
      filter_sort_select: "relevance",
      filter_tag: null,
      filter_start_date: null,
      filter_end_date: null,
      filter_text: "",
    };
  },
  computed: {
    filter_author_options() {
      let lst = [];
      this.users.forEach((usr) => {
        lst.push(usr.username);
      });
      return lst;
    },
  },
  methods: {
    updatePage(new_page) {
      let loader = this.$loading.show();
      http.get('submission/', {
            params: {
              sort_by: null,
              offset: (new_page-1) * this.per_page,
              limit: this.per_page,
              threaded: !this.filter_show,
              filter_username: this.filter_author_select,
              filter_start_date: this.filter_start_date,
              filter_end_date: this.filter_end_date,
              filter_text: this.filter_text,
              filter_tag: this.filter_tag,
            }
          })
      .then((evt) => {
        this.n_logs = evt.data.n_subs;
        this.submissions = [];
        let stack = evt.data.subs;
        while (stack.length > 0) {
          const sub = stack[0];
          this.submissions.push(sub);
          stack.splice(0, 1);
          if (sub.children !== null) {
            sub.children.forEach(child => {
              stack.splice(0, 0, child);
            })
          }
        }
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide();
      })
    },
    showFilter() {
      this.$emit('sync-users');
      this.$emit('sync-tags');
      this.filter_show = true;
    },
    hideFilter() {
      this.filter_show = false;
      this.filter_author_select = null;
      this.filter_start_date = null;
      this.filter_end_date = null;
      this.filter_text = ""
      this.filter_tag = null;
      this.updatePage(1);
    },
    applyFilter() {
      this.updatePage(1);
    },
    loadSubmission(sub) {
      this.$router.push('/submission/'+sub.id);
    },
    loadSubmissionInNewTab(sub) {
      window.open('/submission/'+sub.id);
    },
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  mounted() {
    this.updatePage(this.current_page);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.updatePage(vm.current_page);
    })
  },
}
</script>

<style scoped>

.current-tag {
  border-radius: 30rem !important;
  padding: 3px;
  font-weight: 600;
  font-size: small;
}

</style>